<template>
  <div>
    <van-nav-bar title="扫二维码支付" left-arrow @click-left="$router.go(-1)" />
    <van-notice-bar
      left-icon="volume-o"
      text="如果用户正在扫码支付，请不要切换支付方式，否则可能付费无效。"
    />
    <div style="min-height: 356px">
      <div
        v-if="payInfo"
        class="d-flex flex-direction-y col-center padding-y-30"
      >
        <vue-qr
          :text="payInfo.rqCode"
          :size="250"
          :logoSrc="logo"
          :logoScale="0.2"
        >
        </vue-qr>
        <div
          class="d-flex row-center padding-b-20 font-20"
          style="color: #444"
          v-if="payInfo.money"
        >
          ￥{{ $price(payInfo.money) }}
        </div>
      </div>
    </div>

    <div class="d-flex row-around" style="margin: 16px">
      <van-button type="info" @click="req(1)"> 确认支付 </van-button>
      <van-button plain @click="$router.go(-1)"> 切换支付方式 </van-button>
    </div>

    <van-dialog v-model="show" confirmButtonColor="#1885f4" @confirm="confirm">
      <div
        class="d-flex row-center flex-direction-y col-center"
        style="padding: 50px 0"
      >
        <div><van-icon name="success" color="#67c23a" size="76" /></div>
        <div class="margin-t-10">支付成功！</div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import { createQrCodeBill, checkBill } from "@/apis/water-payment.js";
import VueQr from "vue-qr";
import { Toast, Dialog } from "vant";
export default {
  components: {
    VueQr,
  },
  data() {
    return {
      show: false,
      showPicker: false,
      logo: require("@/assets/logo.png"),
      payInfo: null,
      id: "",
      type: "",
      timer: null,
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.type = this.$route.query.type;
    this.fn();
  },
  beforeDestroy() {
    this.clear();
  },
  methods: {
    clear() {
      try {
        console.log(this.timer);
        this.timer && clearInterval(this.timer);
      } catch (err) {
        console.log(err);
      }
    },
    fn() {
      let toast1 = Toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      });

      createQrCodeBill({
        recordId: this.id - 0,
        payType: this.type - 0,
      })
        .then((res) => {
          toast1.clear();
          if (res.succeeded) {
            this.payInfo = res.data;
            this.showPicker = true;
            this.clear();
            this.timer = setInterval(() => {
              this.req();
            }, 15000);
          } else {
            Dialog.alert({
              title: "提示",
              message: res.errors,
            }).then(() => {
              console.log("点击了弹窗");
              this.$router.go(-1);
            });
          }
        })
        .catch(() => {
          toast1.clear();
        });
    },
    // 查询订单是否成功
    req(flag) {
      let toast2;
      if (flag) {
        toast2 = Toast.loading({
          message: "加载中...",
          forbidClick: true,
          duration: 0,
        });
      }

      checkBill({ RecordId: this.id })
        .then((res) => {
          toast2 && toast2.clear();
          if (res.data.isPaid) {
            this.show = true;
            this.clear();
          } else {
            if (flag) {
              Toast.fail("暂未查询到支付信息!");
            }
          }
        })
        .catch(() => {
          toast2 && toast2.clear();
        });
    },
    confirm() {
      this.$router.replace({
        path: "/list",
      });
    },
  },
};
</script>
