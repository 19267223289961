import request from "@/utils/request";

/**
 * @description: 现场缴费订单并支付
 */
export function payCashBill(params) {
  return request({
    url: "/api/WaterPayment/PayCashBill",
    method: "post",
    data: params,
  });
}

//创建二维码订单
export function createQrCodeBill(params) {
  return request({
    url: "/api/WaterPayment/CreateQrCodeBill",
    method: "post",
    data: params,
    headers: {
      extra: {
        noError: true,
      },
    },
  });
}

//查看订单支付状态
export function checkBill(params) {
  return request({
    url: "/api/WaterPayment/CheckBill",
    method: "get",
    params,
  });
}
